export const REACT_APP_GRAPHQL_URL = "https://api.outfitter.services/graphql"
// export const REACT_APP_GRAPHQL_URL = "https://staging-api.outfitter.services/graphql"
  
// export const REACT_APP_WORDPRESS_URL = "https://outfitter-admin-wp.oneclicksales.xyz"
export const REACT_APP_WORDPRESS_URL = "https://media.outfitter.services"

// export const REACT_APP_GRAPHQL_URL = "http://192.168.0.143:8000/graphql"
// export const REACT_APP_GRAPHQL_URL = "http://192.168.1.29:8000/graphql"
// export const REACT_APP_GRAPHQL_URL = "http://192.168.0.123:3000/graphql"
// export const REACT_APP_GRAPHQL_URL = "http://192.168.1.238:3000/graphql"
// export const REACT_APP_GRAPHQL_URL = "http://192.168.0.146:3000/graphql"
// export const REACT_APP_GRAPHQL_URL = "http://192.168.1.238:3000/graphql"
// export const REACT_APP_GRAPHQL_URL = "http://192.168.0.146:3000/graphql"
//export const REACT_APP_GRAPHQL_URL = "http://0.0.0.0:8000/graphql"
